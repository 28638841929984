import MagnifyingGlassIcon from "https://cdn.jsdelivr.net/npm/@heroicons/react@2.2.0/24/outline/esm/MagnifyingGlassIcon.js"

export default function SearchInput(props) {
    // This is a React component containing an Example component
    // Replace <Example /> with your own code

    return (
        <div style={containerStyle}>
            <MagnifyingGlassIcon
                style={{
                    width: "1em",
                }}
            />
            <input
                style={{
                    flexGrow: 1,
                    border: "2px solid #007ec6",
                    borderRadius: "0.75em",
                    paddingBlock: "0.5em",
                    paddingInline: "0.75em",
                    fontFamily: "Poppins",
                }}
                type="search"
                value={props.search}
                placeholder={props.placeholder ?? "Finde Events in deiner Nähe"}
                onChange={(e) => props.setSearch(e.target.value)}
            />
        </div>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    display: "inline-flex",
    gap: "1em",
    alignItems: "center",
    minWidth: "20em",
}
